<template>
    <footer class="bg-gray-900 text-gray-200 py-12">
        <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="lg:flex lg:justify-between">
                <!-- Company Info -->
                <div class="mb-6 lg:mb-0">
                    <h3 class="text-2xl font-bold text-white">Terre Mechanics</h3>
                    <p class="mt-4 text-gray-400">Innovators in mechanical engineering and technology solutions. We strive to deliver excellence in every project.</p>
                    <div class="mt-4 flex space-x-6">
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" class="hover:text-[#E69737] transition-transform duration-300 ease-in-out transform hover:scale-110">
                            <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M22 12.1c0-5.7-4.8-10.3-10.6-10.3S.8 6.4.8 12.1c0 5.1 4 9.3 9.2 10.2v-7.2H7.6v-2.5h2.4V11c0-2.4 1.5-4 3.7-4 1.1 0 2.1.1 2.1.1v2.3h-1.2c-1.2 0-1.6.8-1.6 1.6v1.8h2.7l-.4 2.5h-2.3v7.2c5.2-.9 9.2-5.1 9.2-10.2z"/>
                            </svg>
                          </a>
                          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" class="hover:text-[#E69737] transition-transform duration-300 ease-in-out transform hover:scale-110">
                            <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M23 3a10.8 10.8 0 0 1-3.1.9 5.4 5.4 0 0 0 2.3-3 10.8 10.8 0 0 1-3.4 1.3A5.4 5.4 0 0 0 16 4a5.4 5.4 0 0 0-5.4 5.4c0 .4 0 .8.1 1.2A15.3 15.3 0 0 1 2.3 3a5.4 5.4 0 0 0 1.7 7.2 5.4 5.4 0 0 1-2.4-.7v.1c0 2.6 1.9 4.8 4.4 5.3a5.4 5.4 0 0 1-2.4.1 5.4 5.4 0 0 0 5.1 3.8A10.8 10.8 0 0 1 1 20.6a15.2 15.2 0 0 0 8.2 2.4c9.8 0 15.2-8.1 15.2-15.2 0-.2 0-.5-.1-.7A10.8 10.8 0 0 0 23 3z"/>
                            </svg>
                          </a>
                          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" class="hover:text-[#E69737] transition-transform duration-300 ease-in-out transform hover:scale-110">
                            <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 2.2c-5.4 0-9.8 4.4-9.8 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8-4.4-9.8-9.8-9.8zm0 17.8c-4.4 0-8.1-3.6-8.1-8.1s3.6-8.1 8.1-8.1 8.1 3.6 8.1 8.1-3.7 8.1-8.1 8.1zm4.8-12.5c.8 0 1.5-.6 1.5-1.5s-.6-1.5-1.5-1.5-1.5.6-1.5 1.5.7 1.5 1.5 1.5zm-3.7 1.5c-2.6 0-4.7 2.1-4.7 4.7 0 2.6 2.1 4.7 4.7 4.7s4.7-2.1 4.7-4.7-2.1-4.7-4.7-4.7z"/>
                            </svg>
                          </a>
                    </div>
                </div>
                <!-- Links Section -->
                <div class="grid grid-cols-2 gap-8 lg:gap-16 sm:grid-cols-3">
                    <!-- Quick Links -->
                    <div>
                        <h2 class="text-lg font-semibold text-white">Quick Links</h2>
                        <ul class="mt-4 space-y-4">
                            <li><a href="/about" class="hover:underline no-underline text-gray-400">About Us</a></li>
                            <li><a href="/services" class="hover:underline no-underline text-gray-400">Services</a></li>
                            <li><a href="/contact" class="hover:underline no-underline text-gray-400">Contact</a></li>
                        </ul>
                    </div>
                    <!-- Services -->
                    <div>
                        <h2 class="text-lg font-semibold text-white">Services</h2>
                        <ul class="mt-4 space-y-4">
                            <li><a href="#" class="hover:underline no-underline text-gray-400">Slope Stabilization</a></li>
                            <li><a href="#" class="hover:underline no-underline text-gray-400">Geosynthetics
                                & Shotcreting
                            </a></li>
                           
                        </ul>
                    </div>
                    <!-- Contact -->
                    <div>
                        <h2 class="text-lg font-semibold text-white">Contact Us</h2>
                        <ul class="mt-4 space-y-4">
                            <li><a href="#" class="hover:underline no-underline text-gray-400">contact@
                                <br> terremechanics.com</a></li>
                            <li><a href="#" class="hover:underline no-underline text-gray-400">+91 7063-861201
                            </a></li>
                            <li><a href="#" class="hover:underline no-underline text-gray-400">Mamring South Sikkim, Opposite Of V-Guard
                                Pvt Ltd.- 737132</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="mt-12 border-t border-gray-700 pt-8 text-center">
                <p class="text-gray-500">© 2024 Terre Mechanics. All Rights Reserved.</p>
            </div>
        </div>
    </footer>
    


</template>
<script>
export default{
    name:'footer_comp'
}
</script>


<style>



</style>