
<template>
  <section class="bg-white dark:bg-gray-900">
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class="max-w-screen-md mb-8 lg:mb-16">
        <h2
          class="text-[#0F364D] hover:text-[#768C9C] no-underline font-sans font-bold text-3xl"
          data-aos="fade-up"
        >
          Our Services
        </h2>
        <p class="text-gray-500 sm:text-lg dark:text-gray-400" data-aos="fade-up">
          We offer a range of specialized services to meet your needs, from design and development to strategy and consulting. Explore what we can do for you.
        </p>
      </div>

      <div class="grid grid-cols-1 gap-8 sm:grid-cols-1 lg:grid-cols-1">
    <!-- Card 1 -->
    <div class="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 hover:shadow-lg transition-shadow duration-300 ease-in-out">
        <div class="flex justify-center items-center w-12 h-12 mb-4 bg-[#0F364D] rounded-full">
            <svg class="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c1.657 0 3-.895 3-2s-1.343-2-3-2-3 .895-3 2 1.343 2 3 2zm0 4c1.657 0 3-.895 3-2s-1.343-2-3-2-3 .895-3 2 1.343 2 3 2zm0 4c1.657 0 3-.895 3-2s-1.343-2-3-2-3 .895-3 2 1.343 2 3 2z"></path>
            </svg>
        </div>
        <h3 class="mb-2 text-xl text-[#0F364D] font-bold">Slope Stabilization</h3>
        <p class="text-gray-500 dark:text-gray-400">
            <strong>Soil Nailing</strong>: This technique involves inserting steel bars into a slope or excavation to reinforce soil without extensive digging. Nails are typically grouted for added strength, and shotcrete is applied to protect against erosion.
        </p>
        <p class="text-gray-500 dark:text-gray-400">
            <strong>Self-Drilling Anchor (SDA)</strong>: The SDA system uses hollow steel bars to stabilize slopes and provide structural support with minimal excavation. Bars are threaded and filled with grout for additional stability.
        </p>
        <img :src='FIMG' alt="ima">
        <p class="text-gray-500 dark:text-gray-400">
            <strong>Components of SDA System</strong>:
            <ul class="list-disc pl-5">
                <li>Hollow Anchor Bars</li>
                <li>Drill Bit</li>
                <li>Injection Pump</li>
                <li>Plate and Nut</li>
                <li>Coupler for bar extension</li>
            </ul>
        </p>
        <img :src='SIMG' alt="ima">
        <p class="text-gray-500 dark:text-gray-400">
            <strong>Rock Bolting Process</strong>:
            <ul class="list-disc pl-5">
                <li>Drilling strategic holes</li>
                <li>Inserting bolts with grouting or expansion mechanisms</li>
                <li>Tensioning with hydraulic jacks</li>
                <li>Anchoring with plates and nuts</li>
                <li>Applying surface protection like shotcrete</li>
            </ul>
        </p>
    </div>

    <!-- Card 2 -->
    <div class="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 hover:shadow-lg transition-shadow duration-300 ease-in-out">
        <div class="flex justify-center items-center w-12 h-12 mb-4 bg-[#0F364D] rounded-full">
            <svg class="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c1.657 0 3-.895 3-2s-1.343-2-3-2-3 .895-3 2 1.343 2 3 2zm0 4c1.657 0 3-.895 3-2s-1.343-2-3-2-3 .895-3 2 1.343 2 3 2z"></path>
            </svg>
        </div>
        <h3 class="mb-2 text-xl text-[#0F364D] font-bold">Geosynthetics</h3>
        <p class="text-gray-500 dark:text-gray-400">Geosynthetics are synthetic products used in geotechnical engineering to enhance soil's mechanical and physical properties. They are used in construction for reinforcement, drainage, filtration, separation, and protection. Applications include slope stabilization, erosion control, retaining walls, and road construction.</p>
        <ul class="text-gray-500 dark:text-gray-400">
            <img :src='TIMG' alt="ima">
            <li><strong>1. Geotextiles:</strong></li>
            <li>Woven Geotextiles: Used for reinforcement and separation.</li>
            <li>Non-woven Geotextiles: Used for filtration, drainage, and protection.</li>
            <li><strong>2. Geogrids:</strong></li>
            <li>Uniaxial Geogrids: Reinforce slopes and retaining walls in one direction.</li>
            <li>Biaxial Geogrids: Provide reinforcement in two directions, ideal for roadways.</li>
            <li><strong>3. Geomembranes:</strong></li>
            <li>Impermeable Liners: Prevent fluid migration in landfills and ponds.</li>
            <li><strong>4. Geocells:</strong></li>
            <li>Soil Stabilization: Reinforce soft soils, roads, and slopes.</li>
            <li>Erosion Control: Prevent soil erosion on slopes.</li>
            <li>Load Support: Distribute loads from heavy traffic in railroads and airfields.</li>
            <li>Retaining Walls: Reduce soil failure by stacking geocells.</li>
            <li><strong>5. Geocomposites:</strong></li>
            <li>Hybrid Materials: Combine geotextiles and geomembranes for drainage and filtration.</li>
            <li><strong>6. Geonets:</strong></li>
            <li>Drainage Networks: Facilitate liquid or gas drainage in containment systems.</li>
        </ul>
    </div>

    <!-- Card 3 -->
    <div class="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 hover:shadow-lg transition-shadow duration-300 ease-in-out">
        <div class="flex justify-center items-center w-12 h-12 mb-4 bg-[#0F364D] rounded-full">
            <svg class="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c1.657 0 3-.895 3-2s-1.343-2-3-2-3 .895-3 2 1.343 2 3 2zm0 4c1.657 0 3-.895 3-2s-1.343-2-3-2-3 .895-3 2 1.343 2 3 2z"></path>
            </svg>
        </div>
        <h3 class="mb-2 text-xl text-[#0F364D] font-bold">Shotcreting</h3>
        <p class="text-gray-500 dark:text-gray-400">Shotcreting is a technique where concrete or mortar is projected at high velocity onto a surface using compressed air. It provides a quick, durable, and versatile solution for applying concrete, especially in areas that are hard to reach.</p>
        <img :src='LIMG' alt="ima">
        <ul class="text-gray-500 dark:text-gray-400">
            <li><strong>Types of Shotcreting:</strong></li>
            <li><strong>1. Wet-mix Shotcrete:</strong> Pre-mixed concrete is pumped and sprayed using compressed air, ideal for large-scale applications requiring continuous spraying.</li>
            <li><strong>2. Dry-mix Shotcrete:</strong> Dry components are mixed and sprayed, with water added at the nozzle, often used in smaller, specialized projects.</li>
            <li><strong>Key Applications:</strong></li>
            <li><strong>1. Slope Stabilization:</strong> Provides a protective layer to prevent erosion or landslides on slopes and rock faces.</li>
            <li><strong>2. Tunneling and Underground Construction:</strong> Offers immediate support and reinforcement for tunnel linings and underground structures.</li>
            <li><strong>3. Repair and Rehabilitation:</strong> Used to repair deteriorated structures like bridges and dams, reinforcing them for long-term durability.</li>
            <li><strong>4. Swimming Pools and Water Features:</strong> Creates custom-shaped pools with durable, waterproof surfaces.</li>
        </ul>
    </div>
</div>







            
    </div>
  </section>
</template>


<script>
// Import AOS for scroll animations
import FIMG from '../assets/images/2.jpg'
import SIMG from '../assets/images/3.jpg'
import TIMG from '../assets/images/6.jpg'
import LIMG from '../assets/images/12.png'
import AOS from "aos";
import "aos/dist/aos.css";
export default {

  data() {
    return {
      FIMG ,
      SIMG ,
      TIMG,
      LIMG// Use this image path in the template
    };
  },
  components: {
   
  },
  mounted() {
    AOS.init({
      duration: 800, // Animation duration
      once: true, // Animation happens only once
    });
  },
};
</script>

<style scoped>
/* Optional custom styling */
</style>
